.profile-modal {
  width: 330px;
  padding: 12px 24px;

  .title {
    margin: 12px 0;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }

  .wallet {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    margin: 24px 0;

    div {
      color: var(--theme-font-color-secondary);
    }

    button {
      background-color: transparent;
      font-size: 18px;
      i {
        color: var(--theme-font-color-secondary);
        transition: all 0.25s ease-in-out;
      }

      &:hover {
        i {
          color: var(--theme-font-color-primary);
        }
        opacity: 0.8;
      }
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin: 18px 0;

    div:first-child {
      font-size: 14px;
      color: var(--theme-font-color-secondary);
    }
  }
}