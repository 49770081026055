.congratulation {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  background-color: #0008;

  .text {
    font-size: 36px;
    color: #e0ff37;
    text-shadow: 0 0 5px black;
    text-align: center;
    font-weight: bold;
  }

  .no-winner {
    font-size: 36px;
    color: #1e7be6;
    text-shadow: 0 0 5px black;
    text-align: center;
    font-weight: bold;
  }
  
  opacity: 0;

  animation-name: reward-modal-disappear;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;

  .confetti {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }
}

@keyframes reward-modal-disappear {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
    backdrop-filter: blur(5px);
  }
  80% { opacity: 1;}
  100% {opacity: 0;}
}
@keyframes reward-img-appear {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}