@import 'components';

* {
  box-sizing: border-box;

  font-family: 'Poppins', sans-serif;
  //font-family: 'DM Sans', sans-serif;
  color: var(--theme-font-color-primary);
}

:root {
  --theme-color-primary: #3772ff;
  --theme-color-secondary: #e4423c;

  --theme-bg-color-primary: #141416;
  --theme-bg-color-secondary: #23262f;
  --theme-bg-color-third: #353945;

  --theme-font-color-primary: #fcfcfd;
  --theme-font-color-secondary: #777e90;

  --theme-border-color: #353945;
}

body {
  padding: 0;
  margin: 0;
  background-color: var(--theme-bg-color-primary);
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ddd9d9;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(89, 92, 95);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(53, 56, 58);
}