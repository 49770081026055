#get-ticket {
  display: flex;
  flex-direction: column;
  gap: 48px;
  align-items: center;

  padding: 90px 0;

  .page-buttons {
    display: flex;
    gap: 32px;
    align-items: center;

    button {
      width: 200px;
      padding: 10px 0;
      background-color: white;
      border-radius: 12px;
      
      color: black;
      font-size: 18px;
      font-weight: bold;

      &.active {
        background-color: var(--theme-color-primary);
        color: var(--theme-font-color-primary);
      }

      @media screen and (max-width: 576px) {
        width: 140px;
      }
    }
  }

  .info, .winners {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    width: 100%;
  }

  h2 {
    margin: 0;
    font-size: 40px;
    text-align: center;

    @media screen and (max-width: 576px) {
      font-size: 28px;
    }
  }

  .left-time {
    font-weight: bold;

    > span {
      color: #2af598;

      font-weight: bold;
      margin-right: 6px;
    }
    
    > span.big {
      font-size: 32px;
    }
  }

  .ticket-form {
    border-radius: 24px;
    overflow: hidden;

    width: 90%;
    max-width: 756px;

    .form-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;
      background: linear-gradient(166.77deg, rgb(59, 65, 85) 0%, rgb(58, 48, 69) 100%);

      h3, p {
        margin: 0;
      }
      h3 {
        font-size: 20px;
        font-weight: bold;
      }
      p {
        font-size: 16px;
      }
    }

    .form-body {
      padding: 24px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      background-color: var(--theme-bg-color-secondary);
      gap: 14px;

      .left {
        width: 180px;
        font-size: 20px;
        font-weight: bold;
      }
      .right {
        width: calc(100% - 200px);
        flex-grow: 1;
        font-size: 28px;
        color: #2af598;
        font-weight: bold;
      }
      .count {
        font-size: 28px;
        color: var(--theme-color-primary);
        font-weight: bold;
        span {
          color: var(--theme-color-primary);
          font-size: 40px;
        }
      }

      button {
        height: 48px;
        padding: 0px 24px;
        background-color: white;

        color: #18181a;
        font-weight: bold;
        
        border-radius: 16px;
        box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
        margin-bottom: 10px;
      }

      @media screen and (max-width: 576px) {
        flex-direction: column;
        align-items: center;

        .left, .right, .count {
          width: auto;
        }
      }
    }
  }

  .prize-distribution {
    font-size: 21px;
    color: var(--theme-font-color-primary);
    font-weight: bold;
    max-width: 720px;
    margin-top: 30px;
    width: 80%;
    text-align: center;

    span {
      color: var(--theme-color-primary);
    }
  }

  .list-container {
    width: 90%;
    max-width: 640px;
    overflow-x: auto;
    padding-bottom: 16px;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    width: 100%;
    min-width: 620px;

    li {
      display: flex;
      border-bottom: 1px solid white;
      align-items: center;

      > div {
        padding: 18px 0 12px 0;
        text-align: center;
        font-weight: bold;
      }

      .round {
        width: 70px;
      }

      .ticket-number {
        width: 160px;
      }

      .when {
        width: 100px;
      }

      .prize {
        width: 140px;
      }

      .name {
        flex-grow: 1;

        display: flex;
        align-items: center;
        justify-content: center;

        .cup {
          width: 24px;
          text-align: center;
          display: flex;
          justify-content: center;
        }
      }

      &.list-header div {
        color: var(--theme-color-primary);
        border-bottom-color: transparent;
      }
    }
  }
  
  .btn-more {
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 8px;

    color: var(--theme-color-primary);
    i {
      color: var(--theme-color-primary);
    }
  }

  .stats {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    width: 90%;
    max-width: 756px;

    .stat {
      border: 1px solid #333;
      border-radius: 8px;
      padding: 12px;
      width: calc((100% - 40px) / 5);
      background-color: var(--theme-bg-color-secondary);

      .title {
        font-size: 14px;
        text-align: center;
        color: var(--theme-font-color-primary);

        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        margin-bottom: 10px;
      }

      .value {
        text-align: center;
        font-weight: bold;
        color: #2af598;
      }
    }

    @media screen and (max-width: 800px) {
      gap: 60px;
      row-gap: 15px;
      flex-wrap: wrap;

      .stat {
        width: 140px;
      }
    }

    @media screen and (max-width: 600px) {
      row-gap: 15px;
      flex-wrap: wrap;
      justify-content: center;

      .stat {
        width: 100%;
        padding: 16px;

        .title {
          font-size: 16px;
          height: auto;
        }
        .value {
          font-size: 18px;
        }
      }
    }
  }
}