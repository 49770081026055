.buy-ticket-modal {
  width: 320px;
  background-color: #27262c;

  .modal-header {
    background-color: var(--theme-bg-color-primary);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;

    div {
      font-size: 20px;
      font-weight: bold;
    }

    button {
      background-color: transparent;
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .modal-body {
    background-color: var(--theme-bg-color-secondary);
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .modal-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .ticket-number {
      width: 100px;
      
      input {
        padding: 6px 16px;
        background-color: transparent;
        border: 1px solid rgb(52, 75, 100);
        border-radius: 8px;
        outline: none;
        text-align: right;
        width: 100%;
        transition: all 0.25s ease-in-out;

        &:focus-within {
          box-shadow: 0 0 6px 2px rgb(12, 201, 201);
        }
      }
    }

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left, .right {
        color: var(--theme-font-color-secondary);
        font-size: 14px;
        font-weight: bold;
      }

      .white {
        color: var(--theme-font-color-primary);
      }

      &.result .right {
        font-size: 18px;
        color: var(--theme-font-color-primary);
      }
    }

    .line {
      height: 1px;
      width: 100%;
      background-color: #6e6683;
      margin: 10px 0;
    }

    > button {
      padding: 14px 0;
      margin-top: 14px;
      border-radius: 14px;
      background-color: #1fc7d4;
      font-size: 16px;
      font-weight: bold;
      box-shadow: #0e0e2c66 0px -1px 0px 0px inset;

      &:disabled {
        background-color: #3c3742;
        border-color: #3c3742;
        box-shadow: none;
        color: #666171;
        cursor: not-allowed;
      }
    }

    p {
      margin: 4px;
      font-size: 12px;
      color: #6e6683;
    }
  }

  .buy-buttons {
    display: flex;
    gap: 16px;

    margin: 6px 0 24px 0;

    button {
      width: 0;
      flex-grow: 1;
      border-radius: 100px;
      color: #1ea2ac;
      background-color: #3b3d4b;
      font-weight: bold;
    }
  }
}