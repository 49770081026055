header {
  display: flex;
  align-items: center;

  border-bottom: 1px solid var(--theme-border-color);

  height: 80px;
  padding: 0 40px;

  background-color: var(--theme-bg-color-primary);

  @media screen and (max-width: 576px) {
    padding: 0 20px;
  }

  .logo {
    @media screen and (max-width: 576px) {
      display: none;
    }
    // a {
      display: flex;
      align-items: center;
      gap: 8px;

      font-size: 18px;
      font-weight: 700;
      color: var(--theme-font-color-primary);

      img {
        width: 80px;
      }
    // }
  }
  .buy-wov-button {
    margin-left: auto;
    position: relative;
    display: inline-block;
    height: 40px;
    padding: 0 26px;
    background-color: var(--theme-bg-color-third);
    color: var(--theme-font-color-primary);
    font-weight: 700;
    border-radius: 100px;
    line-height: 40px;
  }
  .connect-button {
    margin-left: 20px;
    position: relative;

    button {
      height: 40px;
      padding: 0 16px;

      background-color: var(--theme-color-primary);
      color: var(--theme-font-color-primary);
      
      font-weight: 700;
      border-radius: 100px;
      line-height: 1;

    }

    .popup-wrapper {
      position: absolute;
      top: 60px;
      right: 0;
      z-index: 2;

      .popup {
        background-color: var(--theme-bg-color-secondary);
        width: 240px;
        
        border-radius: 8px;
        border: 1px solid var(--theme-border-color);

        display: flex;
        align-items: center;
        flex-direction: column;

        position: relative;
        z-index: 2;

        button {
          background-color: var(--theme-bg-color-secondary);
          width: 100%;
          height: 46px;

          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          z-index: 5;

          div {
            width: 150px;
          }
        }

        &::after {
          content: "";
          width: 30px;
          height: 30px;
          border-radius: 8px;
          border: 1px solid var(--theme-border-color);
          position: absolute;
          transform: rotateZ(45deg);
          background-color: var(--theme-bg-color-secondary);
          right: 30px;
          top: -10px;
          z-index: 1;
        }
      }
    }
  }
}