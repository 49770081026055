.alert-template {
  width: 260px;
  margin: 24px !important;
  padding: 12px 24px;
  font-weight: bold;

  border-radius: 8px;

  &.success {
    background-color: #2af598;
    color: black;
    box-shadow: 0 1px 2px 1px #1ea568;
  }

  &.error {
    background-color: #e05a5a;
    box-shadow: 0 1px 2px 1px #913737;
  }

  &.info {
    background-color: #e7e7e7;
    color: black;
  }
}