button {
  border: none;
  outline: none;
  cursor: pointer;

  transition: all 0.25s ease-in-out;

  &:hover {
    opacity: 0.9;
  }
}

section {
  padding: 60px 0;
}

a {
  text-decoration: none;
}