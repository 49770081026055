#banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;

  position: relative;
  overflow: hidden;

  padding: 50px 0 120px 0;

  .bg-star {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: url(../../assets/images/bg-star.svg) center 0px no-repeat;
    opacity: 0.7;
  }
  .veLottery__logo {
    max-width: 300px;
    @media screen and (max-width: 576px) {
      max-width: 150px;
    }
  }
  h5 {
    color: var(--theme-font-color-primary);
    font-size: 22px;
    margin: 0;
  }

  h4 {
    color: rgb(184, 185, 194);
    font-size: 16px;
    margin: 8px 0 -24px 0;
  }

  .prize-pool-balance {
    font-size: 64px;
    font-weight: bold;
    color: var(--theme-color-primary);

    span {
      color: var(--theme-color-primary);
    }

    @media screen and (max-width: 576px) {
      font-size: 48px;
    }
  }

  .buy-button {
    position: relative;
    height: 110px;
    width: 270px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 36px 0 0 0 ;

    // animation-name: running;
    // animation-duration: 4s;
    // animation-iteration-count: infinite;
    // animation-timing-function: ease-in-out;

    img {
      display: block;
      height: 110px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    button {
      position: relative;

      background-color: white;
      padding: 12px 24px;
      border-radius: 14px;

      color: black;
      font-size: 16px;
      font-weight: 600;

      &:active {
        transform: translateY(2px);
      }
    }
  }

  .left-time {
    font-weight: bold;
    margin-top: 36px;

    > span {
      color: #2af598;

      font-weight: bold;
      margin-right: 6px;
    }
    
    > span.big {
      font-size: 32px;
    }
  }

  .absolute {
    position: absolute;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;

    @media screen and (max-width: 992px) {
      display: none;
    }
  }

}
